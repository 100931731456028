.nav__list {
  margin-right: 1.5em;
  display: flex;
  
}
.nav__list-item {
  margin-left: 1.5em;
}

.app .nav__hamburger {
  display: none;
}

.nav__theme {
  margin-top: 0.4em;
}

@media (max-width: 900px) {
  .nav__list {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .nav__list-item {
    margin: 0.5em 0;
  }

  .app .nav__hamburger {
    display: flex;
    z-index: 2;
    margin-left: 0.8em;
  }
}

.nav__actionbar {
  position: fixed;
  right: 10px;
  bottom: 10px;
}

.show {
  visibility: visible;
  z-index: auto;
  opacity: 1;
}

.hide {
  visibility: hidden;
  opacity: 0;
}

.nav__modal {
  position: fixed;
  bottom: 60px;
  right: 20px;
  z-index: 2;
  background-color:rgb(83, 186, 255);
  border-radius: 18px;
  padding: 0.05em;
}

.action_nav_content {
  flex-direction: column;
  align-items: center;
  font-weight: 1000;
  margin: 0.5em;
}

.link__action {
  color: var(--clr-bg);
  font-size: 1em;
  text-align: center;
  margin: auto;
}



.link_bg {
  background-color: var(--clr-primary);
  padding: 0.5em 1em;
  border-radius: 18px;
  text-align: center;
  margin: auto;
  opacity: 1.0;
}

br {
  content: "";
  margin: 2em;
  display: block;
  font-size: 24%;
}