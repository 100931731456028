.projects__grid {
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18em, 1fr));
  grid-gap: 2em;
}

.date {
  background-color: transparent;
  color: var(--clr-fg-alt)
}

.vertical-timeline-element-title {
  color: var(--clr-text);
  text-align: right;
}

.vertical-timeline-element-subtitle {
  color: var(--clr-text-alt);
}

.readmore {
  position: relative;
  max-height: 100px;
  overflow: hidden;
  padding: 10px;
  margin-bottom: 20px;

  transition:max-height 0.15s ease-out;

}

.readmore.expand{
  max-height: 5000px !important;
  transition:max-height 0.35s ease-in-out;
}

.readmore-link{
  position: absolute;
  bottom: 0;
  right: 0;
  display: block;
  width:100%;
  height: 60px;
  text-align: center;
  color: blue;
  font-weight:bold;
  font-size:16px;
  padding-top:40px;
  background-image: linear-gradient(to bottom, transparent, white);
  cursor: pointer;
}

.readmore-link.expand {
  position: relative;
  background-image: none;
  padding-top:10px;
  height:20px;
}

.readmore-link:after {
  content:"Read more";
}
.readmore-link.expand:after{
  content:"Read less";
}

.dot {
  height: 50px;
  width: 50px;
  background-color: var(--clr-primary);
  border-radius: 50%;
  position: absolute;
  display: flex;
  right: -25px;
  bottom: -25px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}

.cent {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}

.skills__list {
  max-width: 450px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skills__list-item {
  margin: 0.5em;
}
