.about {
  flex-direction: column;
  margin-top: 3em;
  max-width: 1100px;
  margin: 0;
  height: 100%;
}

.about__name {
  margin-top: 2em;
  color: var(--clr-primary);
  font-size: 3.5rem;
  max-width: 600px;
  text-align: center;
}

.about__role {
  margin-top: 0.5em;
  text-align: center;
}

.abour__role__alt {
  color: var(--clr-primary);
}

.about__desc__alt {
  margin-left: auto;
  margin-right: auto;
  padding-right: min(50px, 5%);
  padding-left: min(50px, 5%);
  margin-top: 2em;
  text-align: justify;
  max-width: 800px;
}

.about__contact {
  margin-top: 2.4em;
  align-items: center;
  justify-content: center;
  display: inline-block;
  display: flex;
  justify-content: center;
}

.about .link--icon {
  margin-right: 1.2em;
  color: var(--clr-primary);
}

.about .btn--outline {
  max-height:fit-content;
  margin: 1.2em;
  border-radius: 10px;
  border-width: 5px;
  text-align: center;
}
.toshowborder{
  border-width: 10px;
  border-color: #00ffea;
  border-radius: 25px;
}
.toshow{
  border: 10px;
  border-color: #00ffea;
  border-radius: 25px;
  transition: all .2s ease-out;
  margin: 10px 0;
  width: 100%;
  height: auto;
  -webkit-transition: opacity 600ms, visibility 600ms;
  transition: opacity 600ms, visibility 600ms;
}

.visible {
  opacity: 1;
  max-height: max-content;
  transition-property: all;
	transition-duration: .5s;
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.hidden {
  opacity: 0;
  max-height: 0px;
  transition-property: all;
	transition-duration: .5s;
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.caro {
  margin: 0 auto;
  padding: 0;
  margin-top: -100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  clip-path: circle();
}

.img {
  clip-path: circle();
  background-color: var(--clr-primary);
  
}

@media (max-width: 600px) {
  .app .about {
    align-items: flex-start;
    margin-top: 2em;
  }
}
