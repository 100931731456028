.project {
  padding: 2em;
  margin: 0 auto;
  text-align: center;
  box-shadow: var(--shadow);
  transition: transform 0.2s linear;
}

.project:hover {
  transform: translateY(-7px);
}

.project__description {
  margin-top: 1em;
}

.project__stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1.2em 0;
}

.project__stack-item {
  margin: 0.5em;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--clr-fg-alt);
  bottom: 0;
}

.project .link--icon {
  margin-left: 0.5em;
  position: sticky;
}

.project__bg {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
}

.desc_text {
  font-weight: 1000;
  text-align: center;
}

.modal {
  background-color: bisque;
  position: relative;
  margin: auto;
  width: 75%;
  display: block;
  border-radius: 18px;
}

.modal > .content {
  width: 80%;
  margin: auto;
  padding: 5% 0;
  font-size: 1rem;
  font-weight: 1000;
}

.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #7a7a7a;
  border-radius: 18px;
  border: 1px solid #cfcece;
}


.faded_desc {
  font-size: 16px;
  background-image: linear-gradient(to top, transparent, var(--clr-fg) 300%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: var(--clr-fg-alt);
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--clr-alt);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}