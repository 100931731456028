.scroll-top {
  position: fixed;
  bottom: 10px;
  right: 50px;
  background-color: transparent;
}

@media (max-width: 600px) {
  .scroll-top {
    display: none;
  }
}
